import * as React from 'react';
import Context from './Context';

import { persistAccess, getAccessSettings } from './partner_acccess_utils';
import { persistUtmTracking, getUtms } from './utm_utils';

const Provider = ({ children, query }) => {
  const isClient = typeof window !== 'undefined';

  if (isClient) {
    persistUtmTracking(query);
    persistAccess(query);
  }

  const access = isClient && getAccessSettings();
  const utms = isClient && getUtms();

  const value = { utms, access };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
