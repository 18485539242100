import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import axios from 'axios';
import { AuthHeaders } from 'modules/authentication/authentication';

import { getAuthToken } from 'constants/config';
import { CONTENT_API_ENDPOINT } from 'constants/endpoints';


const AGENT_LINK_ENDPOINT = `${CONTENT_API_ENDPOINT}/agent_link_email`;


const RESET = 'skylark/agentLinkEmail/RESET';
const SEND_LINK_REQUEST = 'skylark/agentLinkEmail/SEND_LINK_REQUEST';
const SEND_LINK_SUCCESS = 'skylark/agentLinkEmail/SEND_LINK_SUCCESS';
const SEND_LINK_FAILURE = 'skylark/agentLinkEmail/SEND_LINK_FAILURE';

const initialState = Immutable.Map({
  status: 'new',
  loading: false,
  loaded: false,
});

function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (typeof object[key] !== 'undefined') {
      formData.append(key, object[key]);
    }
  });
  return formData;
}

export const initializeAgentLink = () => async (dispatch) => {
  dispatch({
    type: RESET,
  });
};


export const sendAgentLink = (payload) => async(dispatch) => {

  dispatch({
    type: SEND_LINK_REQUEST,
  });

  try {
    const results = await axios.post(AGENT_LINK_ENDPOINT, payload, { headers: AuthHeaders() });
    console.log('sendAgentLink > results', results);
    dispatch({
      type: SEND_LINK_SUCCESS,
      payload: results.data,
    });
  } catch (e) {
    console.log('sendAgentLink > onFailure', e);
    const respStatus = e.response ? e.response.status : null;
    dispatch({
      type: SEND_LINK_FAILURE,
      payload: respStatus,
    });
  }
}



const onRequest = (state, action) => state.set('loading', true).set('loaded', false);

const onSuccess = (state) =>
  state
    .set('status', 'success')
    .set('loading', false)
    .set('loaded', true);

const onFailure = (state, action) => {
  const newState = state.set('loading', false).set('loaded', false);
  if (action.payload.status === 500) {
    return newState.set('serverErrors', Immutable.List(action.payload.response.errors));
  }
  if (action.payload.status === 404) {
    return newState.set('serverErrors', Immutable.List(['Page not found']));
  }
  return newState.set('serverErrors', Immutable.List(action.payload.response.errors));
};


// REDUCER
export default function reducer(state = initialState, action) {
  // console.log('ACTION', action.type, action);
  switch (action.type) {
    case RESET:
      return initialState;
    case SEND_LINK_REQUEST:
      return onRequest(state, action);
    case SEND_LINK_SUCCESS:
      return onSuccess(state);
    case SEND_LINK_FAILURE:
      return onFailure(state, action);
    default:
      return state;
  }
}
