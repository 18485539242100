const UTM_PARAM_NAMES = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

const getStringOrFirstElement = (value) => {
  return Array.isArray(value) ? value[0] : value;
};

const dedupeObjectValues = (obj) => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, getStringOrFirstElement(value)]));
};

const filterObjectByKeys = (obj, keys) => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
};

export const persistUtmTracking = (query) => {
  const sessionStore = window.sessionStorage;
  const utmParams = dedupeObjectValues(filterObjectByKeys(query, UTM_PARAM_NAMES));

  const requestContainsValidUtms = !!utmParams.utm_source;

  if (requestContainsValidUtms) {
    const utms = {
      source: utmParams.utm_source,
      medium: utmParams.utm_medium,
      campaign: utmParams.utm_campaign,
      term: utmParams.utm_term,
      content: utmParams.utm_content,
    };
    sessionStore.setItem('utms', JSON.stringify(utms));
  }
};

export const getUtms = () => {
  const sessionStore = window.sessionStorage;
  return sessionStore?.getItem('utms') ? JSON.parse(sessionStore.getItem('utms')) : null;
};
