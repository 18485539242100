import Immutable from 'immutable';

const SELECT_ITINERARY = 'skylark/tripSelection/SELECT_ITINERARY';

// ACTIONS FROM OTHER DUCK
const AUTH_SIGN_OUT = 'skylark/authentication/SIGN_OUT';
const REQUEST_AVAILABILITY = 'skylark/availability/REQUEST';
const REQUEST_MERGED_AVAILABILITY = 'skylark/availability/MERGED_REQUEST';
const REQUEST_MERGED_AVAILABILITY_SUCCESS = 'skylark/availability/MERGED_SUCCESS';
const REQUEST_HOTEL_AVAILABILITY_SUCCESS = 'skylark/hotel_availability/MERGED_SUCCESS';

const initialState = Immutable.Map({
  itinerary: null,
});

export const setItinerary = itinerary => ({ type: SELECT_ITINERARY, itinerary });

const itinerarySet = (state, itinerary) => {
  // console.log('ITINERARY SET', itinerary ? itinerary.toJS() : null);
  const itinMap = itinerary.toJS();
  delete itinMap.legs;
  delete itinMap.segment_keys;
  return state.set('itinerary', itinerary);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ITINERARY:
      return itinerarySet(state, action.itinerary);
    case AUTH_SIGN_OUT:
    case REQUEST_AVAILABILITY:
    case REQUEST_MERGED_AVAILABILITY:
    case REQUEST_MERGED_AVAILABILITY_SUCCESS:
    case REQUEST_HOTEL_AVAILABILITY_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
