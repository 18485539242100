import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';
import { CONTENT_API_ENDPOINT } from 'constants/endpoints';

const INQUIRY_SUCCESS = 'skylark/inquiry/INQUIRY_SUCCESS';
const INQUIRY_REQUEST = 'skylark/inquiry/INQUIRY_REQUEST';
const INQUIRY_FAILURE = 'skylark/inquiry/INQUIRY_FAILURE';
const INQUIRY_RESET = 'skylark/inquiry/INQUIRY_RESET';

const initialState = Immutable.Map({
  submissionSuccess: null,
  loading: false
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INQUIRY_SUCCESS:
      return state.set('submissionSuccess', true).set('loading', false);
    case INQUIRY_REQUEST:
      return state.set('submissionSuccess', null).set('loading', true);
    case INQUIRY_FAILURE:
      return state.set('submissionSuccess', false).set('loading', false);
    case INQUIRY_RESET:
      return initialState;
    default:
      return state;
  }
}

export function resetInquiryForm() {
  return {
    type: INQUIRY_RESET,
  };
}

export function submitInquiryForm(data, browser_session_id = null) {
  data.travel_date = data.travel_date ? data.travel_date.value || data.travel_date : ''; // eslint-disable-line no-param-reassign
  data.budget = data.budget ? data.budget.value || data.budget : ''; // eslint-disable-line no-param-reassign
  data.duration = data.duration ? data.duration.value || data.duration : ''; // eslint-disable-line no-param-reassign
  data.referral = data.referral ? data.referral.value || data.referral : ''; // eslint-disable-line no-param-reassign
  data.travelers = data.travelers ? data.travelers.value || data.travelers : ''; // eslint-disable-line no-param-reassign

  // return { type: NULL };

  return {
    [CALL_API]: {
      endpoint: `${CONTENT_API_ENDPOINT}/travel_inquiries`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      types: [INQUIRY_REQUEST, INQUIRY_SUCCESS, INQUIRY_FAILURE],
      body: JSON.stringify({ travel_inquiry: data, browser_session_id: browser_session_id }),
    },
  };
}
