const ACCESS_PARAM_NAMES = ['partner_code'];
const SYSTEM_ACCESS_ATTRIBUTES = ['overrideHotelsShoppingRestrictions', 'overrideProtectedAmenities', 'overrideSuppressedAmenities'];

const UTM_SOURCE_PARTNER_ACCESS_CODE_MAP = {
  cnt: 'cnt',
  tpg: 'tpg',
  flexjet: 'flexjet',
  agent: 'sky',
};

const PARTNER_ACCESS_SETTINGS = {
  tpg: {
    overrideHotelsShoppingRestrictions: true,
    overrideProtectedAmenities: false,
    overrideSuppressedAmenities: true,
    // persistMethod: 'localStorage',
  },
  cnt: {
    overrideHotelsShoppingRestrictions: true,
    overrideProtectedAmenities: false,
    overrideSuppressedAmenities: true,
    // persistMethod: 'localStorage',
  },
  flexjet: {
    overrideHotelsShoppingRestrictions: true,
    overrideProtectedAmenities: false,
    overrideSuppressedAmenities: true,
    // persistMethod: 'localStorage',
  },
  sky: {
    overrideHotelsShoppingRestrictions: true,
    overrideProtectedAmenities: true,
    overrideSuppressedAmenities: true,
    // persistMethod: 'localStorage',
  },
  default: {
    overrideHotelsShoppingRestrictions: false,
    overrideProtectedAmenities: false,
    overrideSuppressedAmenities: false,
    // persistMethod: 'localStorage',
  },
};

const filterObjectByKeys = (obj, keys) => {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));
};

const getAccessAttributes = (partnerAccessCode) => {
  const access = PARTNER_ACCESS_SETTINGS[partnerAccessCode];
  return filterObjectByKeys(access, SYSTEM_ACCESS_ATTRIBUTES);
};


export const persistAccess = (query) => {
  const localStore = window.localStorage;
  let { partner_code } = filterObjectByKeys(query, ACCESS_PARAM_NAMES);

  if (!partner_code) {
    partner_code = UTM_SOURCE_PARTNER_ACCESS_CODE_MAP[query.utm_source];
  }

  const requestContainsValidAccessParams = !!partner_code;
  if (requestContainsValidAccessParams) {
    localStore.setItem('parterAccessCode', partner_code);
  }
};

export const getAccessSettings = () => {
  const localStore = window.localStorage;
  const partnerAccessCode = localStore.getItem('parterAccessCode');
  const accessSettings = partnerAccessCode ? getAccessAttributes(partnerAccessCode) : null;
  return accessSettings;
};
