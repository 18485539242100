import { useContext } from 'react';
import Context from './Context';
import Provider from './Provider';

const useUtmTracking = () => useContext(Context);

export const UtmTrackingProvider = Provider;
export const UtmTrackingContext = Context;
export const UtmTrackingConsumer = Context.Consumer;
export default useUtmTracking;
